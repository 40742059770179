<template>
  <div>
    权限管理
  </div>
</template>

<script>
export default {
  name: 'rightsmg',
  data () {
    return {};
  },
  mounted () {
  }
};
</script>

<style scoped>

</style>
